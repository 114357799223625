import React from 'react'
import { useState } from 'react'
import css from './styles.module.scss'
import Text from 'components/typo/Text'
import Title from 'src/components/typo/Title'
import Typo from 'components/typo/Typo'
import Image from 'components/shared/Image'
import { ReactComponent as Arrow } from './arrow.svg'
import Link from 'next/link'

const SummarizeBenefits = ({ title, cliContent, features, logos, links }) => {

  const [copySuccess, setCopySuccess] = useState(false)
  const handleCopy = () => {
    navigator.clipboard.writeText(cliContent).then(() => {
      setCopySuccess(true)
      setTimeout(() => setCopySuccess(false), 2000)
    })
  }
  
  return (
    <div className={css.wrapper}>
      <div className={css.leftSide}>
        <Title className={css.title}>{title}</Title>
        <div className={css.contentColumn}>
          {cliContent && (
            <div className={css.codeContainer}>
              <pre className={css.code}>{cliContent}</pre>
              <button className={css.copyButton} onClick={handleCopy}>
                {copySuccess ? 'Copied!' : 'Copy'}
              </button>
            </div>
          )}
          <div className={css.featuresRow}>
            {features &&
              features.map((feature) => (
                <div className={css.oneFeature} key={feature.id}>
                  <Image
                    className={css.featureIcon}
                    media={{ width: 16, height: 16 }}
                    fluid
                    {...feature.icon}
                    alt={
                      feature.icon?.media?.data?.attributes?.alternativeText ??
                      'icon-image'
                    }
                  />
                  <Text className={css.featureTitle}>{feature.title}</Text>
                </div>
              ))}
          </div>
        </div>
        {logos && (
          <div className={css.scrollWrapper}>
          <div className={css.logosRow}>
            {logos.map((logo) => (
              <Image
                key={logo.id}
                className={css.logo}
                fluid
                notAbsolute
                {...logo}
                alt={
                  logo?.media?.data?.attributes?.alternativeText ?? 'logo-image'
                }
              />
            ))}
          </div></div>
        )}
      </div>
      {links && (
        <div className={css.rightSide}>
          {links.map((link) => (
            <div className={css.linksWrapper}>
              <div className={css.border} />
              <div className={css.content}>
                <div className={css.titleRow}>
                  <Image
                    className={css.linkImage}
                    media={{ width: 32, height: 32 }}
                    fluid
                    {...link.icon}
                    alt=""
                  />
                  <div className={css.column}>
                    <Typo className={css.linkTitle}>{link.title}</Typo>
                  </div>
                </div>
                <Text className={css.linkDescription}>{link.text}</Text>
                {link.links.map((sublink) => (
                  <Link className={css.actionLink} href={sublink.href}>
                    <Text className={css.actionLink}> {sublink.text} </Text>
                    <Arrow className={css.arrow}/>
                  </Link>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default SummarizeBenefits
